<template lang="pug">
.form-modal(
  v-if="showFormManager.manageField"
  @mousedown="changeFormManagerVisibility({ hide: 'manageField' })"
)
  .content(style="min-height: fit-content; width: 26.25rem" @mousedown.stop="")
    .header.om-bottom-divider.p-3
      .header-inner.w-100
        .col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('manageInputFields') }}
            om-button.ml-auto.p-1(
              icon="times"
              iconSize="1.715em"
              ghost
              iconOnly
              style="color: #b9bec6"
              @click="goBack()"
            )
    .d-flex
      .row.mr-5.mt-4.mb-2.custom-margin-left
        span.small-alt-title {{ $t('manageInputFieldsModalsTitle') }}
    .d-flex.px-3.py-2.flex-column.scrollable
      .d-flex.flex-row.header-content.py-2.px-3.each-field.align-items-center(
        v-for="field in getFields"
        :key="field.id"
        v-if="isInputEditable(field)"
      )
        .col.col-8
          span {{ field.name }}
        .col.col-4.flex-row.justify-content-end
          om-button(icon="edit-alt" ghost iconSize="1.5em" iconOnly @click="editInput(field)")
          om-button.ml-2(
            v-if="getSelectedElementUsedInputCID !== field.customId"
            icon="trash-alt"
            ghost
            iconSize="1.5em"
            iconOnly
            @click="deleteInput(field)"
          )
    .d-flex.flex-row.header-content.justify-content-end.mr-5.mt-3.mb-4
      om-button(icon="plus-circle" secondary iconSize="1.5em" @click="createNew()") {{ $t('createNew') }}
</template>
<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapState([
        'showFormManager',
        'selectedElement',
        'selectedElementValue',
        'inputs',
        'inputFields',
        'templateSaveData',
      ]),
      ...mapGetters(['hasAccountFeature']),

      getFields() {
        return this.inputFields;
      },
      fieldType() {
        return this.selectedElement.data.form.customSettings.type;
      },
      getSelectedElementUsedInputCID() {
        return this.selectedElement.data.form.customSettings.customId;
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      createNew() {
        this.changeFormManagerVisibility({ hide: 'manageField' });
        this.changeFormManagerVisibility({ show: 'secondStep' });
      },
      isInputEditable(input) {
        return input._id !== null;
      },
      editInput(field) {
        if (!this.isInputEditable(field)) {
          return;
        }

        this.changeFormManagerVisibility({ hide: 'manageField' });
        this.changeFormManagerVisibility({ show: 'thirdStep', selectedElementValue: field });
      },
      deleteInput(field) {
        this.changeFormManagerVisibility({ hide: 'manageField' });
        this.changeFormManagerVisibility({ show: 'deleteFields', selectedElementValue: field });
      },
      goBack() {
        this.changeFormManagerVisibility({ hide: 'manageField' });
      },
      getCustomId(field) {
        if (['email', 'firstname', 'lastname'].includes(field.customId)) {
          return '';
        }
        return ` (${field.customId})`;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .custom-margin-left
    margin-left: 2.5rem
  .small-alt-title
    font-style: normal
    font-weight: 400
    font-size: 14px
    line-height: 20px
    color: #505763
  .each-field
    font-family: Roboto
    font-style: normal
    font-weight: bold
    font-size: 14px
    line-height: 24px
    color: #505763
    &:last-child
      padding-bottom: 1rem !important
    &:first-child
      padding-top: 1rem !important
  .button-link
    color: black !important
  .custom
    font-family: Roboto
    font-style: normal
    font-weight: bold
</style>
