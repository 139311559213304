<template lang="pug">
.form-modal(
  v-if="showFormManager.deleteFields"
  @mousedown="changeFormManagerVisibility({ hide: 'deleteFields' })"
)
  .content(style="min-height: 15rem; width: 30rem" @mousedown.stop="")
    .header.om-bottom-divider.pb-4
      .header-inner.w-100
        .om-col.om-col-12.header-content
          .d-flex.align-items-center
            .mb-0.font-weight-bold(style="font-size: 18px") {{ $t('deleteFieldModal.title') }} {{ inputName }}
            .button.om-close.ml-auto(style="color: #b9bec6" @click="goBack()") &times;
    .om-col.om-col-12.header-content.ml-3.mt-3.delete-field-content
      span {{ $t('deleteFieldModal.text') }}
    .om-row.footer.mt-3
      .om-col.om-col-2
        .button.button-link(@click="goBack()") {{ $t('cancel') }}
      .om-col.om-col-3
        .button.button-primary.button-large.custom(@click="deleteField()") {{ $t('delete') }}
</template>
<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import DELETE_FIELD from '@/graphql/DeleteField.gql';
  import { getBrandedClassString } from '@/components/Elements/Button';

  export default {
    data() {
      return {
        inputNameVal: null,
      };
    },

    computed: {
      ...mapState(['showFormManager', 'inputs', 'selectedElementValue', 'templateSaveData']),
      ...mapGetters(['databaseId']),
      inputName: {
        get() {
          return this.inputNameVal === null ? this.selectedElementValue.name : this.inputNameVal;
        },
      },
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      isInputEditable(input) {
        return input._id !== null;
      },
      async deleteField() {
        this.changeFormManagerVisibility({ hide: 'deleteFields' });
        if (!this.isInputEditable(this.selectedElementValue)) {
          return;
        }

        const {
          data: { deleteField },
        } = await this.$apollo.mutate({
          mutation: DELETE_FIELD,
          variables: {
            customId: this.selectedElementValue.customId,
            databaseId: parseInt(this.databaseId, 10),
          },
        });

        if (deleteField.success) {
          this.$bus.$emit('switchInputElement');

          this.$notify({
            type: 'success',
            text: this.$t('notifications.removeSuccess'),
          });
        } else {
          this.$modal.show('dialog', {
            text: this.$t('fieldUsed', { count: deleteField.count }),
            buttons: [
              {
                title: this.$t('ok'),
                class: getBrandedClassString({ secondary: true }),
                default: true,
              },
            ],
          });
        }
      },
      goBack() {
        this.changeFormManagerVisibility({ hide: 'deleteFields' });
        this.changeFormManagerVisibility({ show: 'manageField' });
      },
    },
  };
</script>
<style lang="sass" scoped>
  .delete-field-content
    font-family: Roboto
    font-style: normal
    font-weight: normal
    font-size: 12px
    line-height: 16px
    color: #8F97A4
  .button-link
    color: black !important
  .custom
    font-family: Roboto
    font-style: normal
    font-weight: bold
</style>
